import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Proptypes from 'prop-types';
import {
  ContentText,
  deleteFieldSelectorStyles,
  DeleteModalTitleText,
  Search,
} from './DeleteFarmAndFields.style';
import { FIELD_SELECTOR_MODAL_CONTENT } from './DeleteFarmAndFields.content';

const DeleteFieldSelector = ({
  open,
  fieldList,
  handleClose,
  handleDelete,
}) => {
  const [selectedField, setSelectedField] = useState(null);

  const handleFieldSelect = (field) => {
    setSelectedField(field);
  };

  const handleModalClose = () => {
    setSelectedField(null);
    handleClose();
  };

  const handleDeleteClick = () => {
    handleDelete(selectedField);
    handleModalClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      sx={deleteFieldSelectorStyles.dialog}>
      <DialogTitle sx={deleteFieldSelectorStyles.dialogTitle}>
        <DeleteModalTitleText>
          {FIELD_SELECTOR_MODAL_CONTENT.TITLE}
        </DeleteModalTitleText>
        <CloseIcon
          onClick={handleModalClose}
          sx={deleteFieldSelectorStyles.closeIcon}
        />
      </DialogTitle>
      <DialogContent sx={deleteFieldSelectorStyles.dialogContent}>
        <ContentText>{FIELD_SELECTOR_MODAL_CONTENT.CONTENT_TEXT}</ContentText>
        <Autocomplete
          ListboxProps={deleteFieldSelectorStyles.autocompleteListBoxProps}
          sx={deleteFieldSelectorStyles.autocomplete}
          options={fieldList}
          value={selectedField}
          onChange={(_, newValue) => {
            handleFieldSelect(newValue);
          }}
          getOptionLabel={(option) => option.fieldName}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search by field name"
              sx={deleteFieldSelectorStyles.textField}
              InputProps={{
                ...params.InputProps,
                startAdornment: <Search />,
                endAdornment: null,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={deleteFieldSelectorStyles.dialogActions}>
        <Button
          onClick={handleModalClose}
          variant="text"
          color="error"
          sx={deleteFieldSelectorStyles.cancelBtn}>
          {FIELD_SELECTOR_MODAL_CONTENT.CANCEL_BTN_LABEL}
        </Button>
        <Button
          onClick={handleDeleteClick}
          variant="contained"
          color="error"
          disabled={!selectedField}
          sx={deleteFieldSelectorStyles.deleteBtn}>
          {FIELD_SELECTOR_MODAL_CONTENT.DELETE_BTN_LABEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteFieldSelector.propTypes = {
  open: Proptypes.bool,
  fieldList: Proptypes.array,
  handleClose: Proptypes.func,
  handleDelete: Proptypes.func,
};

export default DeleteFieldSelector;
