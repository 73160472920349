import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  YesOrNoOptions,
  PAGE_CONTENT,
  formModal,
  errorInitialState,
  FALSE,
  checkFieldIsNotEmpty,
  regexMatchForAcres,
  validateFormFields,
  convertYesNoIntoBoolean,
  formFieldsNames,
  stringToArray,
  convertBooleanToYesNo,
  livestockTypeInitialState,
  FsaIdTypes,
  FsaIdOptions,
} from './ParticipantGeneralFarmInfo.content';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import {
  FormHeading,
  FormLabels,
  deleteFmsIconSx,
} from 'components/FormComponents/FormStyles';
import AddSectionButton from 'components/AddSectionButton/AddSection.react';
import { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Backdrop, CircularProgress, Container, Divider } from '@mui/material';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { useForm } from 'hooks/useForm';
import {
  FormSection,
  IconWrapper,
  InputWithDeleteIconWrapper,
  LivestockTypeLabelWrapper,
  LivestockTypeWrapper,
  MainWrapper,
  SubsectionWrapper,
  TextWrapper,
  radioBtnPadding,
  OwnerDisclaimer,
  OwnerDisclaimerText,
  NameSectionWrapper,
  dropdownInputSx,
  LocationSectionWrapper,
  HeaderWrapper,
  AddButtonSx,
  DeleteIconCustomStyle,
  FormFieldsWrapper,
  AcerageWrapper,
  GeneralInfoWrapper,
  ErrorWrapper,
  headCountStyle,
  customCityStyles,
} from './ParticipantGeneralFarmInfo.style';
import {
  checkTernaryCondition,
  convertSquareMetersToAcres,
  addZeroBeforeDot,
  extractAddrFromGoogleMapComponents,
  getMailingAddress,
  onlyCharsRegex,
  formatPhoneNumber,
  isEmpty,
  findObjectWithKey,
  ifAnyIsTrue,
} from 'utils/helper';
import { useNavigate } from 'react-router';
import { participantInfoContext } from 'contextAPI/participantInfoContext';
import axios from 'axios';
import {
  EDIT_GENERAL_FARM_INFO,
  FETCH_GENERAL_FARM_INFO,
  FETCH_LIVESTOCK_TYPE_DROPDOWN,
  FETCH_EXISITING_PARTICIPANT_CONTRIBUTORS,
  REMOVE_PARTICIPANT_CONTRIBUTOR,
  FETCH_USER_LOCK_DETAILS,
} from 'urls';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  COOL_GRAY,
  WHITE,
  FOCUS_DARK_GREEN,
  NEUTRAL_BACKGROUND_COLOR,
} from 'theme/GlobalColors';
import { getValueOrDefault } from 'pages/AddPurchaseTransaction/AddPurchaseTransaction.content';
import { pathName } from 'Routes/routes.const';
import { useSearchParams } from 'react-router-dom';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  CROPLAND_AREA,
  FARM_AREA,
  checkIsEnrollmentCompleted,
  enrollmentInstanceStatus,
  handleNextInProgrssSwitcher,
} from '../ParticipantDataCollection.content';
import DeleteIconWithHoverEffect from '@mui/icons-material/DeleteOutline';
import { STYLE_CONSTANTS } from '../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import {
  ButtonContainer,
  InputSx,
  NavigationBtnWrapper,
} from '../components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import UserNavigationButtonsV2 from '../components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import GoogleMapSearchAutocomplete from 'components/FormComponents/GoogleMapSearchAutocomplete/GoogleMapSearchAutocomplete.react';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { FORM_CONTENT } from '../../ParticipantDataCollection/components/ParticipantContributorsForm/ParticipantContributorsForm.content';
import AutoCompleteForm from 'components/FormComponents/AutoComplete/AutoComplete.react';

import { autoCompleteSx } from '../components/ParticipantContributorsForm/ParticipantContributorsForm.style';
import AddContributorModal from '../components/AddContributorModal/AddContributorModal.react';
import AddButton from 'components/AddButton/AddButton.react';
import { RadioErrorIconStyle } from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.style';
import { uomContext } from 'contextAPI/unitOfMeasureContext';
import {
  ERROR_MSGS,
  INPUT_CATEGORY,
  PARTICIPANT,
  RIGHT_TEXT_ALIGN,
  TOOLTIP_PLACEMENTS,
} from 'utils/config';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import { useInterval } from 'hooks/useInterval';
import { LiveStockInfoWrapper } from '../components/ParticipantGeneralFieldInfoForm/ParticipantGeneralFieldInfoForm.style';
import PropTypes from 'prop-types';

const fmsObj = {
  id: uniqueId(),
  fmsName: '',
  fmsError: FALSE,
};

const ParticipantGeneralFarmInfo = ({
  switchState,
  handleSwitchChange,
  setIsEditable,
  isEditable,
  isSwitchDisabled,
  setIsSwitchDisabled,
  setProjectIdValue,
  setParticipantIdValue,
  setSwitchState,
  isBlockedModalVisible = false,
}) => {
  const {
    setFarmInfo,
    setExpandedFarmId,
    currentFarmId,
    setCurrentFarmId,
    currentFarmInfo,
    setCurrentFarmInfo,
    farmInfo,
    setSelectedFieldId,
    setShowFieldInfoForm,
    farmInfoCurrentTab,
    setFarmInfoCurrentTab,
    setFetchedFarmValues,
    setFieldAddTypeModalOpen,
    isTabSwitched,
    setCurrentFarmLocation,
    setSubmitData,
    setRightSectionId,
    setUserActivityDetected,
    setModalData,
    participantProfileStatus,
    enrolledFarmIds,
    setIsFarmCsafUpdatedBySystem,
    isFieldSubmitted,
    setIsFieldSubmitted,
    fieldPolygons,
    fetchParticipantData,
    isParticipantContributor,
    triggerFarmInfoSubmit,
    closeUnsavedChangesModal,
    setTriggerFarmInfoSubmit,
    fetchCountyList,
    fetchStateList,
    addedFromUiFarmIds,
    setAddedFromUiFarmIds,
    unsavedChangesModalOpenProps,
    handleSubmitCallback,
    shouldNavigateToLandingPage,
    goToProfile,
    setActiveUser,
    setDisableSubmitAllFarmsBtn,
    setIsEnrollmentCompletedAllTogether,
    isEnrollmentCompletedAllTogether,
    fetchEnrollmentCompletionDetails,
    enrollmentDataCollectionCompleted,
    hasUnApprovedFarmsOrFields,
  } = useContext(participantInfoContext);
  const {
    userDetails: { userId },
    userRoles,
  } = useContext(userAuthRolesContext);
  const currentFarmName = farmInfo.find(
    (farm) => farm.id === currentFarmId,
  )?.value;
  const {
    formValue: generalFarmInfoForm,
    customFieldChangeHandler,
    setFormValue,
  } = useForm({
    ...formModal,
    farmName: checkTernaryCondition(
      currentFarmId === -1,
      'Farm 1',
      currentFarmName,
    ),
  });

  const [submitLoading, setSubmitLoading] = useState(FALSE);
  const [errorState, setErrorState] = useState(errorInitialState);
  const [fmsInfo, setFmsInfo] = useState([fmsObj]);
  const [isSubmit, setIsSubmit] = useState(FALSE);
  const [agronomistOption, setAgronomistOption] = useState('');
  const [fmsOption, setFmsOption] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectIdValue = searchParams.get('projectid');
  const participantIdValue = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');

  const [livestockTypes, setLivestockTypes] = useState(
    livestockTypeInitialState,
  );
  const [livestockTypeDropdownList, setLivestockTypeDropdownList] = useState(
    [],
  );
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true);
  const [formStatus, setFormStatus] = useState('Not Started');
  const toastDisplayedRef = useRef(false);
  const {
    unitOfMeasureList: { farmEnrolment },
  } = useContext(uomContext);

  /**
   * @description Setting user activity flag to true to track web form changes and prompt user to save if they try closing browser window/tab
   */
  const makeFormDirty = () => setUserActivityDetected(true);
  const [autocomplete, setAutocomplete] = useState(null);
  const [countyList, setCountyList] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [existingContributorList, setExistingContributorList] = useState([]);
  const [participantContributors, setParticipantContributors] = useState([]);
  const [openContributorModal, setOpenContributorModal] = useState(false);
  const isParticipantOwner = userRoles?.includes(PARTICIPANT);

  const existingContributors = existingContributorList.reduce((acc, data) => {
    if (
      participantContributors.find(
        (contributor) =>
          contributor === data?.label && typeof data.value === 'number',
      )
    ) {
      acc.push(data.value);
    }
    return acc;
  }, []);

  const newContributors = existingContributorList.reduce((acc, data) => {
    const contributor = participantContributors.find(
      (contributor) =>
        contributor === data?.label && typeof data.value !== 'number',
    );

    if (contributor) {
      acc.push({
        participantEmail: data?.participantEmail,
        participantFirstName: data?.participantFirstName,
        participantLastName: data?.participantLastName,
        phone: formatPhoneNumber(data?.participantPhone),
        primaryCommunication: data.primaryCommunication,
        projectId: Number(projectIdValue),
        participantId: 0,
        participantContributor: true,
        farmIdList: [currentFarmId],
        status: 'New Contributor Invite Sent',
        participantOwnerId: Number(participantIdValue),
        enrollmentInstanceId: Number(enrollmentInstanceId),
        value: data.value,
      });
    }

    return acc;
  }, []);
  useEffect(() => {
    setDisableSubmitAllFarmsBtn(!isEnrollmentCompletedAllTogether);
  }, [isEnrollmentCompletedAllTogether]);
  const removedContributors = existingContributorList.reduce((acc, data) => {
    const value = data.value;
    if (
      !newContributors.map((item) => item.value).includes(value) &&
      !existingContributors.includes(value)
    ) {
      acc.push(value);
    }
    return acc;
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (!switchState) {
      setIsEditable(false);
      setFieldAddTypeModalOpen(false);
      closeUnsavedChangesModal(false);
    }
  }, [switchState]);

  const isFarmNameUnique = useMemo(
    () =>
      farmInfo.reduce(
        (sum, farm) =>
          sum +
          checkTernaryCondition(
            farm.value === generalFarmInfoForm.farmName?.trim() &&
              farm.id !== currentFarmId,
            1,
            0,
          ),
        0,
      ) === 0,
    [generalFarmInfoForm.farmName],
  );

  const getSwitchState = () => {
    axios
      .get(
        `${FETCH_USER_LOCK_DETAILS}/${currentFarmId}?internalProjectId=${projectIdValue}&participantOwnerId=${participantIdValue}`,
      )
      .then((response) => {
        setActiveUser(response.data);
        const { activeUserId } = response.data;
        const disableSwitch =
          !isEmpty(activeUserId) && +activeUserId != +userId;
        setIsSwitchDisabled(disableSwitch);
        if (disableSwitch) {
          setSwitchState(false);
        } else {
          setSwitchState(+activeUserId === +userId);
        }
      })
      .catch(() => {
        /* istanbul ignore next */
        displayToast('error', ERROR_MSGS.FETCH);
      });
  };

  useInterval(async () => {
    /* istanbul ignore else */
    if (
      !isBlockedModalVisible &&
      (isSwitchDisabled || !switchState) &&
      +currentFarmId > 0
    ) {
      getSwitchState();
    }
  }, 10000);

  useEffect(() => {
    /* istanbul ignore else */
    if (+currentFarmId > 0) {
      getSwitchState();
      fetchLivestockTypeOptions().then((data) => {
        fetchFarmData(data);
        setLivestockTypeDropdownList(data);
      });
    }
  }, [switchState]);

  // Submit triggered from tab switch
  useEffect(() => {
    if (triggerFarmInfoSubmit) {
      saveData().then(() => {
        if (unsavedChangesModalOpenProps?.triggeredFrom === 'Tab') {
          setFarmInfoCurrentTab(1); // Switch to view map
          setFetchedFarmValues([]);
          setCurrentFarmLocation(currentFarmId);
          fetchParticipantData(false);
          closeUnsavedChangesModal();
        } else handleSubmitCallback();
      });
      setTriggerFarmInfoSubmit(false);
    }
  }, [triggerFarmInfoSubmit]);

  const getPolygonArea = (polygonCoordinates) =>
    window.google.maps.geometry?.spherical?.computeArea(polygonCoordinates);

  const fieldsAreaExceedsFarmArea = useMemo(() => {
    const currentFarmFieldBoundaries = fieldPolygons
      ?.filter((polygon) => polygon.farmId === currentFarmId)
      .map((polygon) => polygon.fieldBoundaries);
    const farmArea = Number(generalFarmInfoForm.totalFarmAcreage);
    const fieldAreas = currentFarmFieldBoundaries?.map((boundary) =>
      Number(convertSquareMetersToAcres(getPolygonArea(boundary))),
    );
    const totalFieldArea = fieldAreas?.reduce((total, area) => total + area, 0);

    // Note: We are considering a buffer size of 10% on farm area, hence checking with farmArea * 1.1 (This would be -> (farmArea + ((10/100) * farmArea)))
    return isEdit && totalFieldArea > farmArea * 1.1;
  }, [currentFarmId, generalFarmInfoForm.totalFarmAcreage, fieldPolygons]);

  const disableAllFields =
    enrolledFarmIds?.includes(currentFarmId) ||
    !switchState ||
    !isEditable ||
    isSwitchDisabled;
  const disableParticipantContributorButton =
    isParticipantContributor || !switchState || !isEditable;

  useEffect(() => {
    setIsEdit(
      farmInfo.find((farm) => farm.id === currentFarmId)?.isApiPopulated,
    );
  }, [currentFarmId, farmInfo]);

  useEffect(() => {
    setSubmitData({
      currentPage: 'Farm',
      data: generalFarmInfoForm,
      currentId: currentFarmId,
    });
  }, [generalFarmInfoForm, currentFarmId]);

  const resetFormFields = () => {
    setFormValue(() => ({
      ...formModal,
      fsaId: '',
    }));
    setErrorState(errorInitialState);
    setLivestockTypes(livestockTypeInitialState);
    setFmsInfo([fmsObj]);
    setFmsOption('');
    toastDisplayedRef.current = false;
  };

  /**
   * @description This function will set errors for dynamic fields inside web form, other fields error is handled inside a useEffect, can't handle this inside the same.
   * @param data will have the direct api response data.
   */
  const setDynamicFieldsErrors = (data) => {
    /*To be uncommented when CSAF changes are needed in the future. Do not delete.

       const organicStatus = convertBooleanToYesNo(data.farmOrganicStatus);
       const csafExperience = getValueOrDefault(data.csafImplemented);
       const csafPractices = getValueOrDefault(data.extentCsafImplemented);
       const csafFederalFunding = getValueOrDefault(data.csafFederalFunding);
    */
    const agronomistUse = convertBooleanToYesNo(data.agronomistUse);

    setErrorState((prev) => ({
      ...prev,
      /*  To be uncommented when CSAF changes are needed in the future. Do not delete.

        isUSDACertifiedRequiredError: checkTernaryCondition(
        organicStatus === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !convertBooleanToYesNo(data.fieldsOrganicStatus)?.trim(),
        ),
        FALSE,
      ),
      csafPracticesRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        !csafPractices.trim(),
        FALSE,
      ),
      csafFederalFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(!csafFederalFunding?.trim(), FALSE),
      ),
      csafStateFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafStateFunding)?.trim(),
          FALSE,
        ),
      ),
      csafNonProfitFundingRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafNonProfitFunding)?.trim(),
          FALSE,
        ),
      ),
      csafMarketIncentivesRequiredError: checkTernaryCondition(
        csafExperience === PAGE_CONTENT.yes,
        ternaryBooleanHandler(
          !getValueOrDefault(data.csafMarketIncentives)?.trim(),
          FALSE,
        ),
      ), */
      agronomistUseOrganizationRequiredError: checkTernaryCondition(
        agronomistUse === PAGE_CONTENT.yes,
        !getValueOrDefault(data.agronomistUseOrganization)?.trim(),
        FALSE,
      ),
    }));
  };

  // Note: This function currently handles only livestock errors and can be extended to handle others in future.
  function handleErrors(data, newLivestockTypeData) {
    // Same condition as the useEffect which handles all other errors, removed livestockTypeError from useEffect since we want this to happen only on fetch or on change of value of livestock headCount. useEffect will be triggered on any change in form.
    if (
      isEdit &&
      data.formStatus !== PAGE_CONTENT.not_started &&
      !addedFromUiFarmIds.includes(currentFarmId)
    ) {
      setErrorState((prev) => ({
        ...prev,
        livestockTypeError:
          data.isLivestockRaised === PAGE_CONTENT.yes &&
          (isEmpty(newLivestockTypeData) ||
            newLivestockTypeData.every((item) => isEmpty(item.headCountValue))),
      }));
    }
  }

  useEffect(() => {
    fetchEnrollmentCompletionDetails();
    if (currentFarmId && isTabSwitched && currentFarmId !== -1) {
      resetFormFields();
    }
  }, [currentFarmId]);

  useEffect(() => {
    if (isFieldSubmitted && isEdit) {
      fetchExistingContributors();
      fetchLivestockTypeOptions().then((data) => {
        fetchFarmData(data);
        setLivestockTypeDropdownList(data);
      });
    }
  }, [isFieldSubmitted, isEdit]);

  useEffect(() => {
    if (
      isEdit &&
      farmInfoCurrentTab === 0 &&
      currentFarmId !== -1 &&
      typeof currentFarmId !== 'string'
    ) {
      fetchExistingContributors();
      fetchLivestockTypeOptions().then((data) => {
        fetchFarmData(data);
        setLivestockTypeDropdownList(data);
      });
    }
  }, [isEdit, farmInfoCurrentTab, currentFarmInfo, currentFarmId]);

  useEffect(() => {
    if (
      isEdit &&
      generalFarmInfoForm.isApiPopulated &&
      formStatus !== PAGE_CONTENT.not_started &&
      !addedFromUiFarmIds.includes(currentFarmId)
    ) {
      setErrorState((prev) => ({
        ...prev,
        farmNameRequiredError: !generalFarmInfoForm.farmName?.trim(),
        fsaIdRequiredError: !generalFarmInfoForm.fsaId?.trim(),
        fsaIdTypeRequiredError: !generalFarmInfoForm.fsaIdType?.trim(),
        firstNameRequiredError: checkTernaryCondition(
          FsaIdOptions.includes(generalFarmInfoForm.fsaIdType),
          !generalFarmInfoForm.firstName?.trim(),
          FALSE,
        ),
        lastNameRequiredError: checkTernaryCondition(
          FsaIdOptions.includes(generalFarmInfoForm.fsaIdType),
          !generalFarmInfoForm.lastName?.trim(),
          FALSE,
        ),
        entityNameRequiredError: checkTernaryCondition(
          generalFarmInfoForm.fsaIdType === 'Entity',
          !generalFarmInfoForm.entityName?.trim(),
          FALSE,
        ),
        mailingAddressRequiredError:
          !generalFarmInfoForm.mailingAddress?.trim(),
        cityRequiredError: !generalFarmInfoForm.city?.trim(),
        stateRequiredError: !generalFarmInfoForm.state?.trim(),
        countyRequiredError: !generalFarmInfoForm.county?.trim(),
        zipCodeRequiredError: !generalFarmInfoForm.zipCode?.trim(),
        zipCodeInvalidError: generalFarmInfoForm.zipCode?.trim().length < 5,
        totalFarmAcreageRequiredError:
          !generalFarmInfoForm.totalFarmAcreage?.trim(),
        csafExperienceRequiredError:
          !generalFarmInfoForm.csafExperience?.trim(),
        carbonRightsAssociatedError:
          !generalFarmInfoForm.carbonRightsAssociated?.trim(),
        fmsUseRequiredError: !generalFarmInfoForm.fmsUse?.trim(),
        liveStockRequiredError: !generalFarmInfoForm.isLivestockRaised?.trim(),
        fmsListRequiredError: checkTernaryCondition(
          generalFarmInfoForm.fmsUse === PAGE_CONTENT.yes,
          fmsInfo.some((item) => {
            return item.fmsError === true;
          }),
          FALSE,
        ),
        soilSamplingRequiredError: !generalFarmInfoForm.soilSampling?.trim(),
        agronomistUseRequiredError: !generalFarmInfoForm.agronomistUse?.trim(),
        croplandAcerageRequiredError:
          !generalFarmInfoForm.croplandAcerage?.trim(),
        livestockAcerageRequiredError:
          !generalFarmInfoForm.livestockAcerage?.trim(),
        forestAcerageRequiredError: !generalFarmInfoForm.forestAcerage?.trim(),
        croplandAcerageGreaterThanZeroError:
          !isEmpty(generalFarmInfoForm.croplandAcerage?.trim()) &&
          Number(generalFarmInfoForm.croplandAcerage?.trim()) <= 0,
        croplandAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.croplandAcerage?.trim()),
        livestockAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.livestockAcerage?.trim()),
        forestAcerageGreaterThanTotalFarmError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.forestAcerage?.trim()),
        croplandAndForestAcerageSumGreaterThanFarmAreaError:
          Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
          Number(generalFarmInfoForm.croplandAcerage?.trim()) +
            Number(generalFarmInfoForm.forestAcerage?.trim()),
      }));
    }
  }, [generalFarmInfoForm, isEdit, formStatus]);

  useEffect(() => {
    if (isSubmit === true) {
      fmsInfo.forEach((item) => {
        if (item.fmsName === '') {
          setErrorState({
            ...errorState,
            fmsListRequiredError: true,
          });
        }
      });
    }
    setFormValue({
      ...generalFarmInfoForm,
      fmsList: fmsInfo.map((item) => item.fmsName),
    });
  }, [fmsInfo]);

  useEffect(() => {
    fetchEnrollmentCompletionDetails();
    fetchStateList().then((stateList) => setStateList(stateList));
    fetchExistingContributors();
    setProjectIdValue(projectIdValue);
    setParticipantIdValue(participantIdValue);

    return () => {
      setCountyList(null);
      setStateList([]);
    };
  }, []);

  useEffect(() => {
    /* To be uncommented when CSAF changes are needed in the future. Do not delete.
     if (csafExperienceOption === 'No') {
      setFormValue({
        ...generalFarmInfoForm,
        csafFederalFunding: '',
        csafStateFunding: '',
        csafNonProfitFunding: '',
        csafMarketIncentives: '',
      });
    } */
    if (agronomistOption === 'No') {
      setFormValue({
        ...generalFarmInfoForm,
        agronomistUseOrganization: '',
      });
    }
    if (fmsOption === 'No') {
      setFmsInfo([fmsObj]);
    }
  }, [agronomistOption, fmsOption]);

  useEffect(() => {
    setErrorState({
      ...errorState,
      croplandAndForestAcerageSumGreaterThanFarmAreaError:
        Number(generalFarmInfoForm.totalFarmAcreage?.trim()) <
        Number(generalFarmInfoForm.croplandAcerage?.trim()) +
          Number(generalFarmInfoForm.forestAcerage?.trim()),
    });
  }, [generalFarmInfoForm.croplandAcerage, generalFarmInfoForm.forestAcerage]);

  //fetch livestock type options
  const fetchLivestockTypeOptions = async () => {
    return axios.get(FETCH_LIVESTOCK_TYPE_DROPDOWN).then((response) => {
      return response.data.map((data) => ({
        label: data.livestockType,
        value: data.livestockId,
      }));
    });
  };

  const fetchExistingContributors = () => {
    axios
      .get(
        `${FETCH_EXISITING_PARTICIPANT_CONTRIBUTORS}?internalProjectId=${projectIdValue}&participantId=${participantIdValue}`,
      )
      .then((response) => {
        setExistingContributorList(
          response.data.map((data) => ({
            label: `${data.participantFirstName} ${data.participantLastName} <${data.participantEmail}>`,
            value: Number(data.participantId),
            participantId: data.participantId,
            participantFirstName: data.participantFirstName,
            participantLastName: data.participantLastName,
            participantEmail: data.participantEmail,
            phone: data.phone,
            primaryCommunication: data.primaryCommunication,
          })),
        );
      });
  };

  const setFormData = (data, newLivestockTypeData, fromTypeAhead = false) => {
    if (isEdit) {
      setIsEditable(data.isDeleted !== 1);
    }

    !fromTypeAhead &&
      setFarmInfo((prev) =>
        prev.map((farm) =>
          checkTernaryCondition(
            farm.id === currentFarmId,
            {
              ...farm,
              status: data.formStatus,
              formValidationFlag: data.formValidationFlag,
            },
            farm,
          ),
        ),
      );
    const csafUpdatedBySystem = data.csafUpdatedBySystem;
    setIsFarmCsafUpdatedBySystem(csafUpdatedBySystem);
    const formattedData = {
      ...generalFarmInfoForm,
      farmName: getValueOrDefault(data.farmName),
      fsaId: getValueOrDefault(data.fsaId),
      totalFarmAcreage: getValueOrDefault(data.totalFarmAcreage)?.toString(),
      /*
      To be uncommented when CSAF changes are needed in the future. Do not delete.

      organicStatus: convertBooleanToYesNo(data.farmOrganicStatus),
      csafExperience: getValueOrDefault(data.csafImplemented),
      csafFederalFunding: getValueOrDefault(data.csafFederalFunding),
      csafStateFunding: getValueOrDefault(data.csafStateFunding),
      csafNonProfitFunding: getValueOrDefault(data.csafNonProfitFunding),
      csafMarketIncentives: getValueOrDefault(data.csafMarketIncentives), */
      carbonRightsAssociated: convertBooleanToYesNo(
        data.carbonRightsAssociated,
      ),
      fmsUse: convertBooleanToYesNo(data.fmsUsed),
      fmsList: stringToArray(data.fmsList),
      soilSampling: convertBooleanToYesNo(data.soilSampling),
      agronomistUse: convertBooleanToYesNo(data.agronomistUse),
      agronomistUseOrganization: getValueOrDefault(
        data.agronomistUseOrganization,
      ),
      croplandAcerage: getValueOrDefault(data.cropArea)?.toString(),
      livestockAcerage: getValueOrDefault(data.livestockArea)?.toString(),
      forestAcerage: getValueOrDefault(data.forestArea)?.toString(),
      fsaIdType: getValueOrDefault(data.fsaIdType),
      entityName: getValueOrDefault(data.entityName),
      firstName: getValueOrDefault(data.firstName),
      lastName: getValueOrDefault(data.lastName),
      mailingAddress: getValueOrDefault(data.farmMailingAddress),
      city: getValueOrDefault(data.farmCity),
      county: getValueOrDefault(data.farmCounty),
      state: getValueOrDefault(data.farmState),
      zipCode: getValueOrDefault(data.farmZipcode)?.toString(),
      isUSDACertified: convertBooleanToYesNo(data.fieldsOrganicStatus),
      csafPractices: getValueOrDefault(data.extentCsafImplemented),
      csafUpdatedBySystem: csafUpdatedBySystem,
      isApiPopulated: true,
      contributorStatus: data.contributorStatus,
      isLivestockRaised: convertBooleanToYesNo(data.isLivestockRaised),
    };
    setFormValue(formattedData);
    handleErrors(
      { ...formattedData, formStatus: data.formStatus },
      newLivestockTypeData,
    );
    setFormStatus(data.formStatus);
  };

  const fetchFarmData = (livestockOptions) => {
    setSubmitLoading(true);
    setIsDisclaimerVisible(true);
    axios
      .get(
        `${FETCH_GENERAL_FARM_INFO}/${currentFarmId}?internalProjectId=${projectIdValue}&participantOwnerId=${participantIdValue}`,
      )
      .then((response) => {
        const contributorsData = response?.data?.contributorList?.map(
          (contibutor) => {
            return `${contibutor.participantFirstName} ${contibutor.participantLastName} <${contibutor.participantEmail}>`;
          },
        );
        setParticipantContributors(contributorsData);
        const data = response.data;
        const disableSwitch =
          !isEmpty(data?.activeUserId) && +data?.activeUserId != +userId;
        if (disableSwitch) {
          getSwitchState();
          setSwitchState(false);
          setIsSwitchDisabled(disableSwitch);
        } else {
          setSwitchState(+data.activeUserId === +userId);
        }
        const fmsList = stringToArray(data.fmsList);
        const newFmsListInfo = checkTernaryCondition(
          fmsList[0] === '',
          [
            {
              id: uniqueId(),
              fmsName: '',
              fmsError: true,
            },
          ],
          fmsList.map((fetchFms) => ({
            id: uniqueId(),
            fmsName: fetchFms.trim(),
            fmsError: false,
          })),
        );
        setFmsInfo([...newFmsListInfo]);
        const newLivestockTypeData = [];
        for (let i = 0; i < 3; i++) {
          const livestockTypeValue = data[`farmLivestock${i + 1}Id`];
          const headCountValue = data[`farmLivestock${i + 1}headcount`];
          if (
            ifAnyIsTrue(
              livestockTypeValue !== '' && livestockTypeValue !== null,
              headCountValue !== '' && headCountValue !== null,
            )
          ) {
            newLivestockTypeData.push({
              id: uniqueId(),
              livestockTypeValue: livestockTypeValue,
              headCountValue: getValueOrDefault(headCountValue)?.toString(),
              livestockTypeError:
                livestockTypeValue === '' || livestockTypeValue === null,
              headCountError: headCountValue === '' || headCountValue === null,
              name: livestockOptions?.find(
                (type) => type.value === livestockTypeValue,
              )?.label,
            });
          }
        }

        setLivestockTypes(newLivestockTypeData);
        setFormData(data, newLivestockTypeData);
        setDynamicFieldsErrors(data);
        if (currentFarmInfo?.farmId !== data?.farmId) {
          setCurrentFarmInfo(data);
        }
        setTimeout(() => {
          setSubmitLoading(false);
        }, 500);
        setIsFieldSubmitted(false);
        // On initial fetch we set the user activity to false, once any value is changed, we update the flag
        setUserActivityDetected(false);
      });
  };
  useEffect(() => {
    if (livestockTypes.length > 0) {
      setErrorState({
        ...errorState,
        headCountError: livestockTypes.some(
          (item) => item.headCountValue === '',
        ),
      });
    }
  }, [livestockTypes]);

  /**
   *
   * @param {Object} livestockItem -> livestock object to be updated
   * @param {number} value -> newValue to be updated
   */
  function handleLivestockHeadCountUpdate(livestockItem, value) {
    if (value.match(/^\d+$/) || isEmpty(value)) {
      const updatedLivestockData = livestockTypes.map((livestock) => {
        if (livestock.id === livestockItem.id) {
          return {
            ...livestock,
            headCountValue: value,
            headCountError: checkFieldIsNotEmpty(value),
          };
        }
        return livestock;
      });

      const livestockTypeError = updatedLivestockData.every(
        (item) => item.headCountValue === '',
      );

      setLivestockTypes(updatedLivestockData);
      setErrorState((prev) => ({
        ...prev,
        livestockTypeError,
      }));
      makeFormDirty();
    }
  }

  const handleClickDelete = (id) => {
    const updatedLivestockData = livestockTypes.filter((obj) => obj.id !== id);
    setLivestockTypes(updatedLivestockData);

    const deletedLivestockData = livestockTypes.find((obj) => obj.id === id);
    setLivestockTypeDropdownList((prev) => {
      const newItem = {
        value: id,
        label: deletedLivestockData.name,
      };
      let insertIndex = prev.findIndex((item) => item.value > id);
      // If not found, means it should be inserted at the end
      if (insertIndex === -1) insertIndex = prev.length;
      // Insert the new item at the found index
      return [
        ...prev.slice(0, insertIndex),
        newItem,
        ...prev.slice(insertIndex),
      ];
    });
    makeFormDirty();
  };

  const checkTotalAcreageReduction = (
    totalFarmAcreage,
    croplandAcerage,
    forestAcerage,
  ) => {
    return (
      Number(totalFarmAcreage) < Number(croplandAcerage) + Number(forestAcerage)
    );
  };

  const fieldHandler = (key, value) => {
    customFieldChangeHandler(key, value);
    switch (key) {
      case formFieldsNames.totalFarmAcreage:
        setErrorState({
          ...errorState,

          totalFarmAcreageRequiredError: checkFieldIsNotEmpty(value),
          croplandAndForestAcerageSumGreaterThanFarmAreaError:
            checkTotalAcreageReduction(
              value,
              generalFarmInfoForm.croplandAcerage,
              generalFarmInfoForm.forestAcerage,
            ),
          croplandAcerageGreaterThanTotalFarmError:
            Number(value) < Number(generalFarmInfoForm.croplandAcerage),
          forestAcerageGreaterThanTotalFarmError:
            Number(value) < Number(generalFarmInfoForm.forestAcerage),
        });

        break;
      /*    case formFieldsNames.organicStatus:
        setErrorState({
          ...errorState,
          organicStatusRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.isUSDACertified:
        setErrorState({
          ...errorState,
          isUSDACertifiedRequiredError: checkTernaryCondition(
            generalFarmInfoForm.organicStatus === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafExperience:
        setCsafExperienceOption(value);
        setErrorState({
          ...errorState,
          csafExperienceRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.csafFederalFunding:
        setErrorState({
          ...errorState,
          csafFederalFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafStateFunding:
        setErrorState({
          ...errorState,
          csafStateFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafNonProfitFunding:
        setErrorState({
          ...errorState,
          csafNonProfitFundingRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.csafMarketIncentives:
        setErrorState({
          ...errorState,
          csafMarketIncentivesRequiredError: checkTernaryCondition(
            generalFarmInfoForm.csafExperience === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break; */
      case formFieldsNames.fmsUse:
        setFmsOption(value);
        setErrorState({
          ...errorState,
          fmsUseRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.soilSampling:
        setErrorState({
          ...errorState,
          soilSamplingRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.agronomistUse:
        setAgronomistOption(value);
        setErrorState({
          ...errorState,
          agronomistUseRequiredError: checkFieldIsNotEmpty(value),
        });
        break;
      case formFieldsNames.agronomistUseOrganization:
        setErrorState({
          ...errorState,
          agronomistUseOrganizationRequiredError: checkTernaryCondition(
            generalFarmInfoForm.agronomistUse === PAGE_CONTENT.yes,
            checkFieldIsNotEmpty(value),
            FALSE,
          ),
        });
        break;
      case formFieldsNames.croplandAcerage:
        setErrorState({
          ...errorState,
          croplandAcerageRequiredError: checkFieldIsNotEmpty(value),
          croplandAcerageGreaterThanZeroError:
            !isEmpty(value) && Number(value) <= 0,
          croplandAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break;
      case formFieldsNames.isLivestockRaised:
        setErrorState({
          ...errorState,
          livestockTypeError: checkTernaryCondition(
            value === PAGE_CONTENT.yes &&
              (isEmpty(livestockTypes) ||
                livestockTypes.every((item) => item.headCountValue === '')),
          ),
        });
        break;
      /*       case formFieldsNames.livestockAcerage:
        setErrorState({
          ...errorState,
          livestockAcerageRequiredError: checkFieldIsNotEmpty(value),
          livestockAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break;
      case formFieldsNames.forestAcerage:
        setErrorState({
          ...errorState,
          forestAcerageRequiredError: checkFieldIsNotEmpty(value),
          forestAcerageGreaterThanTotalFarmError:
            Number(generalFarmInfoForm.totalFarmAcreage) < Number(value),
        });
        break; */
    }
  };

  const getCurrentFarm = () => {
    return farmInfo.filter((farm) => farm.id === currentFarmId)[0];
  };

  const deleteContributors = async (contributor) => {
    await axios.delete(
      `${REMOVE_PARTICIPANT_CONTRIBUTOR}?farmId=${currentFarmId}&participantOwnerId=${participantIdValue}&participantContributorId=${contributor}&internalProjectId=${Number(
        projectIdValue,
      )}`,
    );
  };

  const saveData = async (event) => {
    event?.target?.blur();
    setSubmitLoading(true);
    setIsSubmit(true);
    if (!isEmpty(removedContributors)) {
      await Promise.all(
        removedContributors.map((contributor) =>
          deleteContributors(contributor),
        ),
      );
    }
    const updatedFmsInfo = fmsInfo.map((item) => {
      if (item.fmsName === '') {
        return {
          ...item,
          fmsError: true,
        };
      }
      return item;
    });
    setFmsInfo(updatedFmsInfo);

    let formStatus = '';
    if (fieldsAreaExceedsFarmArea) {
      formStatus = PAGE_CONTENT.in_progress;
    } else {
      let isFarmCompleted =
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
          isFarmNameUnique,
        ) &&
        farmInfo
          .find((farm) => farm.id === currentFarmId)
          ?.fields?.some((field) => field.status === PAGE_CONTENT.completed);
      formStatus = checkTernaryCondition(
        isEdit,
        checkTernaryCondition(
          isFarmCompleted,
          PAGE_CONTENT.completed,
          PAGE_CONTENT.in_progress,
        ),

        PAGE_CONTENT.in_progress,
      );
    }

    const reqBody = {
      internalProjectId: Number(projectIdValue),
      enrollmentInstanceId: Number(enrollmentInstanceId),
      farmName:
        typeof generalFarmInfoForm.farmName === 'string'
          ? generalFarmInfoForm.farmName
          : generalFarmInfoForm.farmName?.label,
      fsaId:
        typeof generalFarmInfoForm.fsaId === 'string'
          ? generalFarmInfoForm.fsaId
          : generalFarmInfoForm.fsaId?.label,
      contributorStatus: PAGE_CONTENT.in_progress,
      totalFarmAcreage: generalFarmInfoForm.totalFarmAcreage,
      /* organicStatus: convertYesNoIntoBoolean(generalFarmInfoForm.organicStatus),
      csafImplemented: generalFarmInfoForm.csafExperience,
      csafFederalFunding: generalFarmInfoForm.csafFederalFunding,
      csafStateFunding: generalFarmInfoForm.csafStateFunding,
      csafNonProfitFunding: generalFarmInfoForm.csafNonProfitFunding,
      csafMarketIncentives: generalFarmInfoForm.csafMarketIncentives, */
      carbonRightsAssociated: convertYesNoIntoBoolean(
        generalFarmInfoForm.carbonRightsAssociated,
      ),
      fmsUsed: convertYesNoIntoBoolean(generalFarmInfoForm.fmsUse),
      isLivestockRaised: convertYesNoIntoBoolean(
        generalFarmInfoForm.isLivestockRaised,
      ),
      fmsList: generalFarmInfoForm.fmsList.filter((fms) => fms.trim()),
      soilSampling: convertYesNoIntoBoolean(generalFarmInfoForm.soilSampling),
      agronomistUse: convertYesNoIntoBoolean(generalFarmInfoForm.agronomistUse),
      agronomistUseOrganization: generalFarmInfoForm.agronomistUseOrganization,
      cropArea: generalFarmInfoForm.croplandAcerage,
      /* livestockArea: generalFarmInfoForm.livestockAcerage,
      forestArea: generalFarmInfoForm.forestAcerage, */
      formStatus: formStatus,
      formValidationFlag: checkTernaryCondition(
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
          isFarmNameUnique,
        ),
        true,
        false,
      ),
      fsaIdType: generalFarmInfoForm.fsaIdType,
      firstName: generalFarmInfoForm.firstName,
      lastName: generalFarmInfoForm.lastName,
      entityName: generalFarmInfoForm.entityName,
      mailingAddress: generalFarmInfoForm.mailingAddress,
      city: generalFarmInfoForm.city,
      county: generalFarmInfoForm.county,
      state: generalFarmInfoForm.state,
      farmZipcode: generalFarmInfoForm.zipCode,
      /*  fieldsOrganicStatus: convertYesNoIntoBoolean(
        generalFarmInfoForm.isUSDACertified,
      ), */
      extentCsafImplemented: generalFarmInfoForm.csafPractices,
      farmLivestock1Id: checkTernaryCondition(
        livestockTypes[0]?.livestockTypeValue,
        livestockTypes[0]?.livestockTypeValue,
        '',
      ),
      farmLivestock1headcount: checkTernaryCondition(
        livestockTypes[0]?.headCountValue,
        parseInt(livestockTypes[0]?.headCountValue),
        '',
      ),
      farmLivestock2Id: checkTernaryCondition(
        livestockTypes[1]?.livestockTypeValue,
        livestockTypes[1]?.livestockTypeValue,
        '',
      ),
      farmLivestock2headcount: checkTernaryCondition(
        livestockTypes[1]?.headCountValue,
        parseInt(livestockTypes[1]?.headCountValue),
        '',
      ),
      farmLivestock3Id: checkTernaryCondition(
        livestockTypes[2]?.livestockTypeValue,
        livestockTypes[2]?.livestockTypeValue,
        '',
      ),
      farmLivestock3headcount: checkTernaryCondition(
        livestockTypes[2]?.headCountValue,
        parseInt(livestockTypes[2]?.headCountValue),
        '',
      ),
      projectCycleId: +projectCycleId,
      participantOwnerId: Number(participantIdValue),
      participantExistingContributors: Array.from(
        new Set(existingContributors),
      ),
      participantNewContributor: newContributors.map((contributor) => {
        const contributorData = { ...contributor };
        delete contributorData.value;
        return contributorData;
      }),
    };

    return axios
      .put(
        `${EDIT_GENERAL_FARM_INFO}/${currentFarmId}?participantId=${participantIdValue}`,
        reqBody,
      )
      .then((response) => {
        setUserActivityDetected(false);
        setAddedFromUiFarmIds((prev) =>
          prev.filter((farmId) => farmId !== response.data.farmId),
        );
        const updatedFarmInfo = farmInfo.map((farm) => {
          return checkTernaryCondition(
            farm.id === currentFarmId,
            {
              id: response.data.farmId,
              status: response.data.formStatus,
              formValidationFlag: response.data.formValidationFlag,
              value: generalFarmInfoForm.farmName,
              fields: farm.fields,
              isApiPopulated: true,
            },
            farm,
          );
        });
        fetchParticipantData(false);
        setFarmInfo(updatedFarmInfo);
        setCurrentFarmInfo(response.data);
        fetchExistingContributors();
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
        return { responseData: response.data, updatedFarmInfo };
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
        setRightSectionId(2);
        setFarmInfoCurrentTab(0);
        setFieldAddTypeModalOpen(false);
        setCurrentFarmId(currentFarmId);
        setExpandedFarmId(currentFarmId);
        setShowFieldInfoForm(false);
      })
      .finally(() => {
        setSubmitLoading(FALSE);
      });
  };

  const handleContinue = (event) => {
    saveData(event)
      .then(({ responseData, updatedFarmInfo }) => {
        setFetchedFarmValues([]);
        //handles zero fields and zero non approved fields
        const currentFarmFields = getCurrentFarm()?.fields.filter(
          (field) =>
            field.enrollmentStatus !== enrollmentInstanceStatus.approved,
        );
        if (currentFarmFields.length === 0) {
          // If no fields are there, then switch to map.
          setFarmInfoCurrentTab(1);
          setCurrentFarmLocation(responseData.farmId);
        } else {
          // If there are fields, go to next inprogress field or farm.
          navigateToNextSectionHandler(responseData, updatedFarmInfo);
        }
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      });
  };

  const navigateToNextSectionHandler = (farmData, updatedFormInfo) => {
    let isEnrollmentCompleted = checkIsEnrollmentCompleted(
      updatedFormInfo,
      validateFormFields(
        generalFarmInfoForm,
        livestockTypes,
        !fieldsAreaExceedsFarmArea,
        isFarmNameUnique,
      ),
      participantProfileStatus,
      isParticipantContributor,
    );
    handleEnrollmentcompletion(isEnrollmentCompleted, farmData);
  };

  const handleEnrollmentcompletion = (isEnrollmentCompleted, farmData) => {
    setIsEnrollmentCompletedAllTogether(isEnrollmentCompleted);
    setDisableSubmitAllFarmsBtn(!isEnrollmentCompleted);
    if (!isEnrollmentCompleted) {
      setExpandedFarmId(farmData.farmId);
      let inProgressItem = handleNextInProgrssSwitcher({
        updatedFarmInfo: farmInfo,
        currentFarm: getCurrentFarm(),
        currentField: null,
        isFarmWebform: true,
        participantProfileStatus: participantProfileStatus,
      });

      formInProgressHandler(inProgressItem, getCurrentFarm());
    }
  };

  const formInProgressHandler = (inProgressItem, currentFarm) => {
    if (inProgressItem) {
      if (inProgressItem.type === 'field') {
        /* istanbul ignore else */
        if (inProgressItem.farmId !== currentFarmId && switchState) {
          handleSwitchChange({ target: { checked: false } });
        }
        setSelectedFieldId({
          id: inProgressItem.id,
          name: inProgressItem.value,
        });
        setCurrentFarmId(inProgressItem.farmId);
        setExpandedFarmId(inProgressItem.farmId);
        setShowFieldInfoForm(farmInfoCurrentTab === 0);
      } else if (inProgressItem.type === 'farm') {
        /* istanbul ignore else */
        if (inProgressItem.id !== currentFarmId && switchState) {
          handleSwitchChange({ target: { checked: false } });
        }
        setCurrentFarmId(inProgressItem.id);
        setExpandedFarmId(inProgressItem.id);
        setShowFieldInfoForm(false);
      } else if (inProgressItem.type === 'profile') {
        goToProfile();
      }
    } else if (currentFarm?.fields.length > 0) {
      setSelectedFieldId({
        id: currentFarm.fields[0].id,
        name: currentFarm.fields[0].value,
      });
      setShowFieldInfoForm(farmInfoCurrentTab === 0);
    } else {
      setFarmInfoCurrentTab(1);
    }
  };

  const addFmsHandler = () => {
    setFmsInfo((prev) => [
      ...prev,
      {
        ...fmsObj,
        id: uniqueId(),
      },
    ]);
  };

  const handleSubmitAllFarms = () => {
    // Goes into this if when farm is locked but user clicks on "Submit all farms", there is nothing to save at this point so we open the review modal based on the user roles
    // Also this can only be triggered when enrollment data collection is completed(All statuses in "Completed" state)
    if (!switchState) {
      setModalData({
        flag: !(isParticipantContributor || isParticipantOwner),
        id: 0,
      });
      return;
    }

    saveData().then((result) => {
      if (!result) return;
      const { updatedFarmInfo } = result;
      let isEnrollmentCompleted = checkIsEnrollmentCompleted(
        updatedFarmInfo,
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
          isFarmNameUnique,
        ),
        participantProfileStatus,
        isParticipantContributor,
      );
      setIsEnrollmentCompletedAllTogether(isEnrollmentCompleted);
      setDisableSubmitAllFarmsBtn(!isEnrollmentCompleted);

      if (isEnrollmentCompleted) {
        /* istanbul ignore else */
        if (switchState) {
          handleSwitchChange({ target: { checked: false } });
        }

        setModalData({
          flag: isEnrollmentCompletedAllTogether,
          id: 0,
        });
      } else {
        fetchFarmData(null);
      }
    });
  };

  const updateFmsInfo = (event, id) => {
    setFmsInfo((current) => {
      return current.map((fms) => {
        if (fms.id === id) {
          if (event.target.value !== '') {
            setErrorState({
              ...errorState,
              fmsListRequiredError: FALSE,
            });
            return {
              ...fms,
              fmsName: event.target.value,
              fmsError: FALSE,
            };
          }
          if (isSubmit === true) {
            setErrorState({
              ...errorState,
              fmsListRequiredError: true,
            });
            return {
              ...fms,
              fmsName: event.target.value,
              fmsError: true,
            };
          }
          return {
            ...fms,
            fmsName: event.target.value,
          };
        } else {
          return fms;
        }
      });
    });
  };

  const deleteFmsInfo = (id) => {
    setFmsInfo((current) => {
      return current.filter((fms) => {
        return fms.id !== id;
      });
    });
    makeFormDirty();
  };

  /* ***This code is commented but can be reused once csaf is enabled***
    const csafPracticesDropdownProps = {
    label: PAGE_CONTENT.csaf_practices_label,
    value: checkTernaryCondition(
      generalFarmInfoForm.csafPractices === '',
      '',
      generalFarmInfoForm.csafPractices,
    ),
    dropdownlist: csafPracticesOptions,
    isDisabled:
      disableAllFields && isFarmCsafUpdatedBySystem && enableCsafChildren,

    height: '2rem',
    onUpdate: (e) => {
      customFieldChangeHandler(formFieldsNames.csafPractices, e.target.value);
      setErrorState((prev) => ({ ...prev, csafPracticesRequiredError: FALSE }));
      makeFormDirty();
    },
    setStateValue: () => {},
    name: formFieldsNames.csafPractices,
    ariaLabel: PAGE_CONTENT.csaf_practices_label,
    error:
      errorState.stateRequiredError &&
      (!disableAllFields || isFarmCsafUpdatedBySystem || enableCsafChildren),
    fontSize: STYLE_CONSTANTS.input_font_size,
    hasNoBottomMargin: true,
    labelMarginTop: STYLE_CONSTANTS.one_point_five_rem,
    labelMarginBottom: STYLE_CONSTANTS.zero_point_two_five_rem,
    customSx: outlinedDropdownInputSx,
    isAutoWidth: true,
  }; */

  const onSearchLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const updateCountyList = (stateId) => {
    if (stateId)
      fetchCountyList(stateId).then((countyList) => setCountyList(countyList));
  };

  const onPlaceChanged = () => {
    if (autocomplete === null) return;

    const place = autocomplete.getPlace();
    fieldHandler(
      formFieldsNames.mailingAddress,
      `${place.name}${getMailingAddress(place.address_components)}`,
    );
    if (place.address_components) {
      const address = extractAddrFromGoogleMapComponents(
        place.address_components,
      );
      const { city, state, zipCode, county } = address;

      if (generalFarmInfoForm?.state === state) {
        updateCountyList(
          stateList.find((state) => state.value === generalFarmInfoForm?.state)
            ?.stateId,
        );
      }

      setFormValue((prev) => ({
        ...prev,
        city: city,
        state: state,
        zipCode: zipCode,
        county: county,
      }));
    }
  };

  useEffect(() => {
    if (generalFarmInfoForm?.state !== '') {
      updateCountyList(
        stateList.find((state) => state.value === generalFarmInfoForm?.state)
          ?.stateId,
      );
    }
  }, [generalFarmInfoForm.state]);

  const addContributorSectionHandler = () => {
    setOpenContributorModal(true);
  };

  const handleLiveStockAdd = (id) => {
    const filterLivestockType = livestockTypeDropdownList.find(
      (livestockType) => livestockType.value === id,
    );
    const newLivestockTypeData = {
      id: filterLivestockType.value,
      livestockTypeValue: filterLivestockType.value,
      headCountValue: '',
      livestockTypeError: false,
      headCountError: false,
      name: filterLivestockType.label,
    };
    setLivestockTypes((prevState) => {
      return [...prevState, newLivestockTypeData];
    });
    setLivestockTypeDropdownList(
      livestockTypeDropdownList.filter(
        (livestockType) => livestockType.value !== id,
      ),
    );
  };

  const handleSaveClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }
    saveData().then((result) => {
      if (!result) return;
      const { updatedFarmInfo } = result;
      let isEnrollmentCompleted = checkIsEnrollmentCompleted(
        updatedFarmInfo,
        validateFormFields(
          generalFarmInfoForm,
          livestockTypes,
          !fieldsAreaExceedsFarmArea,
          isFarmNameUnique,
        ),
        participantProfileStatus,
        isParticipantContributor,
      );
      setIsEnrollmentCompletedAllTogether(isEnrollmentCompleted);
      setDisableSubmitAllFarmsBtn(!isEnrollmentCompleted);
    });
  };

  const handleContinueClick = async () => {
    const shouldNavigate = await shouldNavigateToLandingPage();
    if (shouldNavigate) {
      navigate(pathName.participant.landingPage);
      return;
    }

    handleContinue();
  };

  return (
    <>
      <Backdrop
        sx={{ color: WHITE, zIndex: 999999 }}
        open={submitLoading}
        data-testId="loader">
        <CircularProgress color={PAGE_CONTENT.success} />
      </Backdrop>
      <FormSection>
        <GeneralInfoWrapper minHeight="calc(100vh - 19rem)">
          <MainWrapper data-testid="Farm-section">
            <Container>
              <FormFieldsWrapper>
                <InputField
                  value={generalFarmInfoForm.farmName}
                  label={PAGE_CONTENT.farm_name_label}
                  placeholder={PAGE_CONTENT.farm_name_placeholder}
                  width="34.5rem"
                  minWidth="0rem"
                  name={formFieldsNames.farmName}
                  onUpdate={(e) => {
                    const newVal = e.target.value;
                    customFieldChangeHandler(formFieldsNames.farmName, newVal);
                    setErrorState((prev) => ({
                      ...prev,
                      farmNameRequiredError: newVal.length === 0,
                    }));
                    makeFormDirty();
                  }}
                  category="name-field"
                  primaryError={errorState?.farmNameRequiredError}
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  customInputSx={InputSx}
                  isDisabled={isParticipantContributor || disableAllFields}
                  maxLength={80}
                />
                <InputField
                  value={generalFarmInfoForm.fsaId}
                  label={PAGE_CONTENT.FSA_farm_ID_label}
                  placeholder={PAGE_CONTENT.FSA_farm_placeholder}
                  width="16.75rem"
                  minWidth="0rem"
                  name={formFieldsNames.fsaId}
                  rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                  onUpdate={(e) => {
                    const newVal = e.target.value;
                    customFieldChangeHandler(formFieldsNames.fsaId, newVal);
                    setErrorState((prev) => ({
                      ...prev,
                      fsaIdRequiredError: newVal.length === 0,
                    }));
                    makeFormDirty();
                  }}
                  category={INPUT_CATEGORY.TEXT}
                  primaryError={errorState?.fsaIdRequiredError}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  customInputSx={InputSx}
                  isDisabled={isParticipantContributor || disableAllFields}
                  tooltipContent={PAGE_CONTENT.FSA_farm_ID_tooltip}
                  infoTooltipPlacement={TOOLTIP_PLACEMENTS.BOTTOM_START}
                  hasTooltip
                />
                <RadioButtonsGroup
                  isOptional
                  selectedValue={generalFarmInfoForm.fsaIdType}
                  list={FsaIdTypes}
                  label={PAGE_CONTENT.fsaId_type_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    setFormValue({
                      ...generalFarmInfoForm,
                      fsaIdType: e.target.value,
                      entityName: '',
                      firstName: '',
                      lastName: '',
                    });
                    makeFormDirty();
                    setErrorState((prev) => ({
                      ...prev,
                      fsaIdTypeRequiredError: FALSE,
                      firstNameRequiredError: FALSE,
                      lastNameRequiredError: FALSE,
                      entityNameRequiredError: FALSE,
                    }));
                  }}
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  hasTooltipOnLabel
                  tooltipContent={PAGE_CONTENT.fsaId_type_tooltip}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                  isDisabled={isParticipantContributor || disableAllFields}
                />
                {generalFarmInfoForm.fsaIdType === PAGE_CONTENT.entity && (
                  <InputField
                    value={generalFarmInfoForm.entityName}
                    label={PAGE_CONTENT.entity_name_label}
                    placeholder={PAGE_CONTENT.entity_name_placeholder}
                    width={PAGE_CONTENT.hundered_percent}
                    name={formFieldsNames.entityName}
                    onUpdate={(e) => {
                      const newVal = e.target.value;
                      customFieldChangeHandler(
                        formFieldsNames.entityName,
                        newVal,
                      );
                      setErrorState((prev) => ({
                        ...prev,
                        entityNameRequiredError: newVal.length === 0,
                      }));
                      makeFormDirty();
                    }}
                    category="name-field"
                    primaryError={errorState?.entityNameRequiredError}
                    hasTooltip
                    tooltipContent={PAGE_CONTENT.entity_name_tooltip}
                    labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    isDisabled={isParticipantContributor || disableAllFields}
                  />
                )}
                {generalFarmInfoForm.fsaIdType ===
                  PAGE_CONTENT.someone_else && (
                  <NameSectionWrapper>
                    <InputField
                      value={generalFarmInfoForm.firstName}
                      category="name-field"
                      label={PAGE_CONTENT.first_name_label}
                      placeholder={PAGE_CONTENT.first_name_placeholder}
                      customStyles={{ width: '49.3%' }}
                      name={formFieldsNames.firstName}
                      onUpdate={(e) => {
                        const newVal = e.target.value;
                        customFieldChangeHandler(
                          formFieldsNames.firstName,
                          newVal,
                        );
                        setErrorState((prev) => ({
                          ...prev,
                          firstNameRequiredError: newVal.length === 0,
                        }));
                        makeFormDirty();
                      }}
                      width="16.75rem"
                      primaryError={errorState?.firstNameRequiredError}
                      hasTooltip
                      tooltipContent={PAGE_CONTENT.first_name_tooltip}
                      labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                      labelMarginBottom={
                        STYLE_CONSTANTS.zero_point_two_five_rem
                      }
                      rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                      customInputSx={InputSx}
                      minWidth={STYLE_CONSTANTS.zero_rem}
                      isDisabled={isParticipantContributor || disableAllFields}
                    />
                    <InputField
                      value={generalFarmInfoForm.lastName}
                      category="name-field"
                      label={PAGE_CONTENT.last_name_label}
                      width="16.75rem"
                      placeholder={PAGE_CONTENT.last_name_placeholder}
                      customStyles={{ width: '49.3%' }}
                      name={formFieldsNames.lastName}
                      onUpdate={(e) => {
                        const newVal = e.target.value;
                        customFieldChangeHandler(
                          formFieldsNames.lastName,
                          newVal,
                        );
                        setErrorState((prev) => ({
                          ...prev,
                          lastNameRequiredError: newVal.length === 0,
                        }));
                        makeFormDirty();
                      }}
                      primaryError={errorState?.lastNameRequiredError}
                      labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                      labelMarginBottom={
                        STYLE_CONSTANTS.zero_point_two_five_rem
                      }
                      rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                      customInputSx={InputSx}
                      minWidth={STYLE_CONSTANTS.zero_rem}
                      isDisabled={isParticipantContributor || disableAllFields}
                    />
                  </NameSectionWrapper>
                )}

                <GoogleMapSearchAutocomplete
                  label={PAGE_CONTENT.mailing_address_label}
                  value={generalFarmInfoForm.mailingAddress}
                  valueChangeHandler={(e) => {
                    const newVal = e.target.value;
                    customFieldChangeHandler(
                      formFieldsNames.mailingAddress,
                      newVal,
                    );
                    setErrorState((prev) => ({
                      ...prev,
                      mailingAddressRequiredError: newVal.length === 0,
                    }));
                    makeFormDirty();
                  }}
                  placeholder={PAGE_CONTENT.mailing_address_placeholder}
                  onPlaceChanged={onPlaceChanged}
                  onSearchLoad={onSearchLoad}
                  hasError={errorState?.mailingAddressRequiredError}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  isDisabled={isParticipantContributor || disableAllFields}
                />

                <LocationSectionWrapper>
                  <InputField
                    value={generalFarmInfoForm.city}
                    category="name-field"
                    label={PAGE_CONTENT.city_label}
                    placeholder={PAGE_CONTENT.city_placeholder}
                    minWidth="0rem"
                    width="100%"
                    name={formFieldsNames.city}
                    customStyles={customCityStyles}
                    onUpdate={(e) => {
                      if (onlyCharsRegex.test(e.target.value)) {
                        const newVal = e.target.value;
                        customFieldChangeHandler(formFieldsNames.city, newVal);
                        setErrorState((prev) => ({
                          ...prev,
                          cityRequiredError: newVal.length === 0,
                        }));
                        makeFormDirty();
                      }
                    }}
                    primaryError={errorState?.cityRequiredError}
                    labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                    isDisabled={isParticipantContributor || disableAllFields}
                  />
                  <DropDown
                    label={PAGE_CONTENT.state_label}
                    value={checkTernaryCondition(
                      isEmpty(generalFarmInfoForm.state),
                      PAGE_CONTENT.state_placeholder,
                      generalFarmInfoForm.state,
                    )}
                    dropDownPlaceholder={PAGE_CONTENT.state_placeholder}
                    dropdownlist={stateList}
                    width="100%"
                    minWidth="6.25rem"
                    showLabelAsValue={true}
                    height="2rem"
                    onUpdate={(e) => {
                      const newState = e.target.value;
                      setFormValue((prev) => ({
                        ...prev,
                        state: newState,
                        county: '',
                      }));
                      setErrorState((prev) => ({
                        ...prev,
                        stateRequiredError: FALSE,
                      }));
                      makeFormDirty();
                    }}
                    setStateValue={() => {
                      /* do nothing here */
                    }}
                    name={''}
                    ariaLabel={''}
                    error={errorState?.stateRequiredError}
                    customPlaceholderColor={checkTernaryCondition(
                      isEmpty(generalFarmInfoForm.farmState),
                      COOL_GRAY,
                      null,
                    )}
                    hasNoBottomMargin={true}
                    labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customSx={dropdownInputSx}
                    isDisabled={isParticipantContributor || disableAllFields}
                  />
                  <DropDown
                    label={PAGE_CONTENT.county_label}
                    value={checkTernaryCondition(
                      isEmpty(generalFarmInfoForm.county),
                      PAGE_CONTENT.county_placeholder,
                      generalFarmInfoForm.county,
                    )}
                    dropDownPlaceholder={PAGE_CONTENT.county_placeholder}
                    dropdownlist={countyList}
                    width="100%"
                    minWidth="6.25rem"
                    showLabelAsValue={true}
                    height="2rem"
                    onUpdate={(e) => {
                      const newVal = e.target.value;
                      customFieldChangeHandler(formFieldsNames.county, newVal);
                      setErrorState((prev) => ({
                        ...prev,
                        countyRequiredError: FALSE,
                      }));
                      makeFormDirty();
                    }}
                    setStateValue={() => {}}
                    name={''}
                    ariaLabel={''}
                    error={errorState?.countyRequiredError}
                    customPlaceholderColor={checkTernaryCondition(
                      isEmpty(generalFarmInfoForm.farmCounty),
                      COOL_GRAY,
                      null,
                    )}
                    hasNoBottomMargin={true}
                    labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customSx={dropdownInputSx}
                    isDisabled={isParticipantContributor || disableAllFields}
                  />
                  <InputField
                    value={generalFarmInfoForm.zipCode ?? ''}
                    label={PAGE_CONTENT.zip_code_label}
                    placeholder={PAGE_CONTENT.zip_code_placeholder}
                    customStyles={{ minWidth: '6.25rem' }}
                    width={'6rem'}
                    minWidth="6.25rem"
                    name="zipCode"
                    category="number-field"
                    maxLength={5}
                    onUpdate={(e) => {
                      const newVal = e.target.value;
                      if (newVal.match(/^\d*$/)) {
                        customFieldChangeHandler(
                          formFieldsNames.zipCode,
                          newVal,
                        );
                        setErrorState((prev) => ({
                          ...prev,
                          zipCodeRequiredError:
                            newVal.toString().trim().length === 0,
                          zipCodeInvalidError: newVal?.toString().length < 5,
                        }));
                        makeFormDirty();
                      }
                    }}
                    primaryError={
                      errorState?.zipCodeRequiredError ||
                      errorState?.zipCodeInvalidError
                    }
                    labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                    labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                    customInputSx={InputSx}
                    rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                    isDisabled={isParticipantContributor || disableAllFields}
                    textAlignment={RIGHT_TEXT_ALIGN}
                  />
                </LocationSectionWrapper>
              </FormFieldsWrapper>
              <HeaderWrapper>
                <InputField
                  isDisabled={disableAllFields}
                  value={generalFarmInfoForm.totalFarmAcreage}
                  label={PAGE_CONTENT.total_farm_acreage_label}
                  placeholder={PAGE_CONTENT.total_farm_acreage_placeholder}
                  width="4.8rem"
                  minWidth="0rem"
                  hasPredefinedUnit={true}
                  predefinedUnit={
                    findObjectWithKey(farmEnrolment, FARM_AREA)?.FARM_AREA[0]
                      ?.uomNameDisplay
                  }
                  isRequired
                  category={INPUT_CATEGORY.LONG_NUMBER}
                  name={formFieldsNames.totalFarmAcreage}
                  onUpdate={(e) => {
                    if (regexMatchForAcres(e.target.value)) {
                      fieldHandler(
                        formFieldsNames.totalFarmAcreage,
                        addZeroBeforeDot(e.target.value),
                      );
                      makeFormDirty();
                    }
                  }}
                  primaryError={
                    errorState.totalFarmAcreageRequiredError &&
                    !disableAllFields
                  }
                  secondaryError={fieldsAreaExceedsFarmArea}
                  secondaryErrorMessage={
                    PAGE_CONTENT.fieldsAreaExceedingFarmAreaError
                  }
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  customInputSx={InputSx}
                  rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                  textAlignment={RIGHT_TEXT_ALIGN}
                />
                <InputField
                  isDisabled={disableAllFields}
                  category={INPUT_CATEGORY.LONG_NUMBER}
                  value={generalFarmInfoForm.croplandAcerage}
                  label={PAGE_CONTENT.cropland_acerage_heading_label}
                  placeholder={PAGE_CONTENT.cropland_acerage_placeholder}
                  width="4.8rem"
                  minWidth="0rem"
                  isRequired
                  hasPredefinedUnit={true}
                  predefinedUnit={
                    findObjectWithKey(farmEnrolment, CROPLAND_AREA)
                      ?.CROPLAND_AREA[0]?.uomNameDisplay
                  }
                  name={formFieldsNames.croplandAcerage}
                  onUpdate={(e) => {
                    if (regexMatchForAcres(e.target.value)) {
                      fieldHandler(
                        formFieldsNames.croplandAcerage,
                        addZeroBeforeDot(e.target.value),
                      );
                      makeFormDirty();
                    }
                  }}
                  primaryError={
                    errorState.croplandAcerageRequiredError && !disableAllFields
                  }
                  rightSideLabelFormLabelMargin={PAGE_CONTENT.zero_rem}
                  secondaryError={
                    errorState.croplandAcerageGreaterThanZeroError &&
                    !disableAllFields
                  }
                  secondaryErrorMessage={
                    PAGE_CONTENT.cropland_acerage_secondary_error_message
                  }
                  thirdError={
                    errorState.croplandAcerageGreaterThanTotalFarmError
                  }
                  thirdErrorMessage={
                    PAGE_CONTENT.cropland_acerage_third_error_message
                  }
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  rightSideLabelErrorMarginBottom={
                    STYLE_CONSTANTS.zero_point_two_five_rem
                  }
                  customInputSx={InputSx}
                  textAlignment={RIGHT_TEXT_ALIGN}
                />
              </HeaderWrapper>
              <AcerageWrapper>
                <RadioButtonsGroup
                  isDisabled={disableAllFields}
                  selectedValue={generalFarmInfoForm.isLivestockRaised}
                  list={YesOrNoOptions}
                  label={PAGE_CONTENT.livestock_heading_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    fieldHandler(
                      formFieldsNames.isLivestockRaised,
                      e.target.value,
                    );
                    makeFormDirty();
                  }}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  primaryError={
                    errorState.liveStockRequiredError && !disableAllFields
                  }
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                />
                {generalFarmInfoForm.isLivestockRaised === PAGE_CONTENT.yes && (
                  <div>
                    <LivestockTypeLabelWrapper>
                      <FormLabels
                        marginTop={STYLE_CONSTANTS.one_point_five_rem}
                        marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}>
                        <LiveStockInfoWrapper>
                          {PAGE_CONTENT.livestock_type_label}
                          {errorState.livestockTypeError && (
                            <AlertIcon
                              data-testId="error-icon"
                              style={RadioErrorIconStyle}
                            />
                          )}
                        </LiveStockInfoWrapper>
                      </FormLabels>
                      {livestockTypes.length > 0 && (
                        <FormLabels
                          marginTop={STYLE_CONSTANTS.one_point_five_rem}
                          marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                          flexDirection="row">
                          {PAGE_CONTENT.head_count_label}
                          {livestockTypes.some(
                            (livestockType) => livestockType.headCountError,
                          ) && (
                            <AlertIcon
                              data-testId="error-icon"
                              style={RadioErrorIconStyle}
                            />
                          )}
                        </FormLabels>
                      )}
                    </LivestockTypeLabelWrapper>
                    {livestockTypes?.map((type) => {
                      return (
                        <LivestockTypeWrapper key={type.id}>
                          <TextWrapper
                            fontWeight="400"
                            fontSize="0.875rem"
                            color={FOCUS_DARK_GREEN}
                            width={'52%'}
                            lineHeight={'1.225rem'}>
                            {type.name}
                          </TextWrapper>
                          <InputField
                            isDisabled={disableAllFields}
                            noLabel
                            category={INPUT_CATEGORY.LONG_NUMBER}
                            maxLength={10}
                            value={type.headCountValue}
                            placeholder={PAGE_CONTENT.head_count_placeholder}
                            width="100%"
                            customStyles={headCountStyle}
                            minWidth="0rem"
                            name={formFieldsNames.livestockAcerage}
                            onUpdate={(event) =>
                              handleLivestockHeadCountUpdate(
                                type,
                                event.target.value,
                              )
                            }
                            primaryError={
                              (type.headCountError && !disableAllFields) ||
                              (errorState.livestockTypeError &&
                                !disableAllFields)
                            }
                            rightSideLabelFormLabelMargin={
                              STYLE_CONSTANTS.zero_rem
                            }
                            customInputSx={InputSx}
                          />
                          <IconWrapper>
                            {!disableAllFields && (
                              <DeleteIconWithHoverEffect
                                sx={DeleteIconCustomStyle}
                                data-testid="livestock-delete-icon"
                                onClick={() => handleClickDelete(type.id)}
                              />
                            )}
                          </IconWrapper>
                        </LivestockTypeWrapper>
                      );
                    })}
                    {errorState.livestockTypeError && (
                      <ErrorWrapper>
                        {PAGE_CONTENT.livestock_error_state}
                      </ErrorWrapper>
                    )}
                    {!disableAllFields && livestockTypes.length < 3 && (
                      <AddButton
                        options={livestockTypeDropdownList.map((item) => ({
                          id: item.value,
                          name: item.label,
                        }))}
                        hasStartIcon={true}
                        handleForProjectActivities={true}
                        onClickHandlerForProjectActivities={handleLiveStockAdd}
                        buttonName={PAGE_CONTENT.livestock_button_label}
                        customBtnSx={AddButtonSx}
                      />
                    )}
                  </div>
                )}
                <RadioButtonsGroup
                  isDisabled={disableAllFields}
                  selectedValue={generalFarmInfoForm.carbonRightsAssociated}
                  list={YesOrNoOptions}
                  label={PAGE_CONTENT.carbon_rights_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    fieldHandler(
                      formFieldsNames.carbonRightsAssociated,
                      e.target.value,
                    );
                    makeFormDirty();
                  }}
                  primaryError={
                    errorState.carbonRightsAssociatedError && !disableAllFields
                  }
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                />
                <RadioButtonsGroup
                  isDisabled={disableAllFields}
                  selectedValue={generalFarmInfoForm.fmsUse}
                  list={YesOrNoOptions}
                  label={PAGE_CONTENT.fms_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    fieldHandler(formFieldsNames.fmsUse, e.target.value);
                    setFmsInfo([fmsObj]);
                    makeFormDirty();
                  }}
                  primaryError={
                    errorState.fmsUseRequiredError && !disableAllFields
                  }
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                  tooltipContent={PAGE_CONTENT.fms_tooltip_content}
                  hasTooltipOnLabel
                />
                {generalFarmInfoForm.fmsUse === PAGE_CONTENT.yes && (
                  <SubsectionWrapper>
                    <FormLabels
                      marginTop={STYLE_CONSTANTS.zero_point_five_rem}
                      marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                      iserror={errorState.fmsListRequiredError}
                      flexDirection={PAGE_CONTENT.row}>
                      {PAGE_CONTENT.fms_list_label}
                      {fmsInfo?.some((fms) => fms.fmsError) &&
                        !disableAllFields && (
                          <AlertIcon
                            data-testId="error-icon"
                            style={RadioErrorIconStyle}
                          />
                        )}
                    </FormLabels>
                    {fmsInfo.length > 0 &&
                      fmsInfo.map((fms, index) => {
                        let id = fms.id;
                        return (
                          <InputWithDeleteIconWrapper key={id}>
                            <InputField
                              isDisabled={disableAllFields}
                              noLabel
                              value={fms.fmsName}
                              //find if change is required
                              // width="34.5rem"
                              minWidth="0rem"
                              category="name-field"
                              maxLength={500}
                              placeholder={PAGE_CONTENT.fms_list_placeholder}
                              isRequired
                              name={`${formFieldsNames.fmsList}${index}`}
                              primaryError={fms.fmsError && !disableAllFields}
                              onUpdate={(event) => {
                                updateFmsInfo(event, id);
                                makeFormDirty();
                              }}
                              deleteTestId={'delete-icon'}
                              rightSideLabelFormLabelMargin={
                                STYLE_CONSTANTS.zero_rem
                              }
                              customInputSx={InputSx}
                            />
                            {!disableAllFields && fmsInfo.length > 1 && (
                              <IconWrapper>
                                <DeleteIconWithHoverEffect
                                  data-testid="delete-icon"
                                  onClick={() => deleteFmsInfo(id)}
                                  sx={deleteFmsIconSx}
                                />
                              </IconWrapper>
                            )}
                          </InputWithDeleteIconWrapper>
                        );
                      })}
                    {!disableAllFields && (
                      <AddSectionButton
                        onClickHanlder={addFmsHandler}
                        label={PAGE_CONTENT.add_fms_button_label}
                        marginTop={STYLE_CONSTANTS.one_rem}
                        marginBottom={STYLE_CONSTANTS.zero_rem}
                        customTransform="none"
                        hasBorder={true}
                        padding="0.375rem 0.5rem"
                      />
                    )}
                  </SubsectionWrapper>
                )}
                <RadioButtonsGroup
                  isDisabled={disableAllFields}
                  selectedValue={generalFarmInfoForm.soilSampling}
                  list={YesOrNoOptions}
                  label={PAGE_CONTENT.soil_sampling_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    fieldHandler(formFieldsNames.soilSampling, e.target.value);
                    makeFormDirty();
                  }}
                  primaryError={
                    errorState.soilSamplingRequiredError && !disableAllFields
                  }
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                />
                <RadioButtonsGroup
                  isDisabled={disableAllFields}
                  selectedValue={generalFarmInfoForm.agronomistUse}
                  list={YesOrNoOptions}
                  label={PAGE_CONTENT.agronomic_advisor_used_or_not_label}
                  direction={PAGE_CONTENT.row}
                  handleChange={(e) => {
                    fieldHandler(formFieldsNames.agronomistUse, e.target.value);
                    setErrorState((prev) => ({
                      ...prev,
                      agronomistUseOrganizationRequiredError: FALSE,
                    }));
                    makeFormDirty();
                  }}
                  primaryError={
                    errorState.agronomistUseRequiredError && !disableAllFields
                  }
                  marginRight={PAGE_CONTENT.margin_right}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                />
                {generalFarmInfoForm.agronomistUse === PAGE_CONTENT.yes && (
                  <SubsectionWrapper>
                    <InputField
                      isDisabled={disableAllFields}
                      value={generalFarmInfoForm.agronomistUseOrganization}
                      label={PAGE_CONTENT.agronomist_organization_label}
                      placeholder={
                        PAGE_CONTENT.agronomist_organization_placeholder
                      }
                      width={PAGE_CONTENT.hundered_percent}
                      isRequired
                      category={INPUT_CATEGORY.TEXT}
                      rightSideLabelFormLabelMargin={STYLE_CONSTANTS.zero_rem}
                      maxLength={100}
                      name={formFieldsNames.agronomistUseOrganization}
                      onUpdate={(e) => {
                        fieldHandler(
                          formFieldsNames.agronomistUseOrganization,
                          e.target.value,
                        );
                        makeFormDirty();
                      }}
                      primaryError={
                        errorState.agronomistUseOrganizationRequiredError &&
                        !disableAllFields
                      }
                      customInputSx={InputSx}
                      labelMarginTop={STYLE_CONSTANTS.zero_point_five_rem}
                      labelMarginBottom={
                        STYLE_CONSTANTS.zero_point_two_five_rem
                      }
                    />
                  </SubsectionWrapper>
                )}
                <FormHeading marginTop="1.5rem">
                  {PAGE_CONTENT.add_participant_contributor_heading}
                </FormHeading>
                {isDisclaimerVisible && (
                  <>
                    <OwnerDisclaimer
                      backgroundColor={checkTernaryCondition(
                        isParticipantContributor,
                        NEUTRAL_BACKGROUND_COLOR,
                        WHITE,
                      )}>
                      <OwnerDisclaimerText>
                        {PAGE_CONTENT.NOTE}
                        {PAGE_CONTENT.owner_disclaimer_text}
                      </OwnerDisclaimerText>
                    </OwnerDisclaimer>
                    <AutoCompleteForm
                      hideInput
                      disableClearable
                      hideNoOptionsLabel
                      isDisabled={disableParticipantContributorButton}
                      isOptional={true}
                      label={PAGE_CONTENT.add_participant_contributors_label}
                      value={[...new Set(participantContributors)]}
                      valueSetter={(value) => {
                        setParticipantContributors(value);
                        makeFormDirty();
                      }}
                      hasNoBottomMargin={true}
                      list={[
                        ...new Set(
                          existingContributorList
                            ?.filter((option) => typeof option !== 'string')
                            ?.map((data) => data.label),
                        ),
                      ]}
                      primaryError={false}
                      placeholder={
                        participantContributors?.length === 0 &&
                        PAGE_CONTENT.ADD_PARTICIPANT_CONTRIBUTORS_PLACEHOLDER
                      }
                      formValue={[...new Set(participantContributors)]}
                      onClickHandler={() => {}}
                      customSx={autoCompleteSx(
                        participantContributors?.length > 0,
                      )}
                      marginTop={STYLE_CONSTANTS.one_rem}
                      labelGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                      marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                      hasNewStyles={true}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      isContributors={true}
                    />
                  </>
                )}
                <AddSectionButton
                  disabled={disableParticipantContributorButton}
                  label={FORM_CONTENT.add_participant_contributors_btn_label}
                  onClickHanlder={addContributorSectionHandler}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  hasBorder={true}
                  marginBottom="1.5rem"
                  width="fit-content"
                  padding="0.2rem 0.5rem"
                  customTransform="none"
                />

                {openContributorModal && (
                  <AddContributorModal
                    setExistingContributorList={setExistingContributorList}
                    setParticipantContributors={setParticipantContributors}
                    participantContributors={participantContributors}
                    exisitingContributorsList={existingContributorList}
                    isModalOpen={openContributorModal}
                    setIsModalOpen={setOpenContributorModal}
                    participantOwnerId={participantIdValue}
                    internalProjectId={Number(projectIdValue)}
                  />
                )}
              </AcerageWrapper>
            </Container>
          </MainWrapper>
        </GeneralInfoWrapper>
        <NavigationBtnWrapper>
          <Divider />
          <ButtonContainer>
            <UserNavigationButtonsV2
              handleContinue={handleContinueClick}
              handleSave={handleSaveClick}
              disabledContinue={!switchState}
              disabledSave={!switchState}
              disableSubmitAllFarms={
                !(
                  enrollmentDataCollectionCompleted &&
                  hasUnApprovedFarmsOrFields
                )
              }
              handleSubmitAllFarms={handleSubmitAllFarms}
            />
          </ButtonContainer>
        </NavigationBtnWrapper>
      </FormSection>
    </>
  );
};

export default ParticipantGeneralFarmInfo;

ParticipantGeneralFarmInfo.propTypes = {
  switchState: PropTypes.bool,
  handleSwitchChange: PropTypes.func,
  setIsEditable: PropTypes.func,
  isEditable: PropTypes.bool,
  isSwitchDisabled: PropTypes.bool,
  setIsSwitchDisabled: PropTypes.func,
  setProjectIdValue: PropTypes.func,
  setParticipantIdValue: PropTypes.func,
  setSwitchState: PropTypes.func,
  isBlockedModalVisible: PropTypes.bool,
};
