import PropTypes from 'prop-types';
import DialogBox from 'components/DialogBox/DialogBox.react';
import React, { useState, useEffect } from 'react';
import { useFirebaseSignIn } from 'security';
import styled from 'styled-components';
import { TIMEOUT_CONTENT } from './TimeoutComponent.content';
import { checkTernaryCondition } from 'utils/helper';

export const TimerContent = ({
  warningTimeout,
  handleLogout,
  timeoutText = TIMEOUT_CONTENT.SECOND_TEXT,
  nextLineTimeOutText = false,
  styles = {},
}) => {
  return (
    <p style={styles}>
      {checkTernaryCondition(
        nextLineTimeOutText,
        TIMEOUT_CONTENT.FORM_LOCKED_TEXT,
        TIMEOUT_CONTENT.FIRST_TEXT,
      )}
      <CountDownTimer seconds={warningTimeout / 1000} logOut={handleLogout} />
      {nextLineTimeOutText && (
        <>
          <br />
          <br />
        </>
      )}
      {timeoutText}
    </p>
  );
};

TimerContent.propTypes = {
  warningTimeout: PropTypes.number.isRequired,
  handleLogout: PropTypes.func.isRequired,
  timeoutText: PropTypes.string,
  nextLineTimeOutText: PropTypes.bool,
  styles: PropTypes.object,
};

function IdleMonitor() {
  const {
    handleLogout,
    idleModal,
    setIdleModal,
    warningTimeout,
    restartTimer,
    resetTimer,
    pause,
  } = useFirebaseSignIn();

  const handleConfirm = () => {
    setIdleModal(false);
    resetTimer();
    restartTimer();
  };

  return (
    <DialogBox
      dialogActions
      customSx={{ width: '503px', margin: 'auto' }}
      buttonSX={{ border: '1px solid', minWidth: '81px', right: '18.5rem' }}
      isOpen={idleModal}
      onCancel={(_event, reason) => {
        if (reason && reason === 'backdropClick') return;
        resetTimer();
        pause();
        handleLogout();
      }}
      subtitle={
        <TimerContent
          warningTimeout={warningTimeout}
          handleLogout={handleLogout}
        />
      }
      onConfirm={handleConfirm}
      declineCtnLabel={`Log out`}
      acceptCtnLabel={`Continue`}
      isCloseEnabled={false}
      title={`Your session is about to expire`}></DialogBox>
  );
}

export function CountDownTimer({ seconds, logOut }) {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((pre) => {
        if (Math.floor(pre) <= 0) {
          clearInterval(timer);
          logOut();
          return 0;
        } else return pre - 1;
      });
    }, 1000);
  }, []);

  return (
    <Time>
      {`${Math.floor(time / 60)}`.padStart(2, 0)}:
      {`${time % 60}`.padStart(2, 0).slice(0, 2)}
    </Time>
  );
}

CountDownTimer.propTypes = {
  seconds: PropTypes.number.isRequired,
  logOut: PropTypes.func.isRequired,
};

const Time = styled.span`
  color: #da291c;
  font-weight: bold;
  marginright: 0.5rem;
`;

export default IdleMonitor;
