import DialogBox from 'components/DialogBox/DialogBox.react';
import { FARM_LOCK_MODALS_DATA } from 'utils/config';
import PropTypes from 'prop-types';

const FarmLockModal = ({
  isOpen,
  onClose,
  onExtend,
  title,
  subtext,
  subtitleSx,
}) => {
  return (
    <DialogBox
      dialogActions
      isOpen={isOpen}
      onCancel={(_event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      subtitle={subtext}
      onConfirm={onExtend}
      declineCtnLabel={FARM_LOCK_MODALS_DATA.BUTTONS.CLOSE}
      acceptCtnLabel={FARM_LOCK_MODALS_DATA.BUTTONS.EXTEND_SESSION}
      isCloseEnabled={true}
      title={title}
      subtitleSx={subtitleSx}
    />
  );
};

export default FarmLockModal;

FarmLockModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExtend: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  subtitleSx: PropTypes.object,
};
