import PropTypes from 'prop-types';
import './styles.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { checkTernaryCondition } from 'utils/helper';
import { COOL_GRAY, DARK_CHARCOAL, DARK_GREEN } from 'theme/GlobalColors';
import { pointerCursor } from 'theme/GlobalStyles';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
  approvalModalButtonStyle,
  approvalModalDialogStyle,
} from 'components/FormComponents/FormStyles';
import LightTooltip from 'components/LightTooltip';
import { DialogTooltipSx } from 'pages/OriginationProjectList/OriginationProjectList.style';

const DialogBox = ({
  title = '',
  subtitle,
  modalTitleSx,
  isError,
  children,
  transactionList,
  isOpen = false,
  onConfirm,
  onCancel,
  acceptCtnLabel,
  declineCtnLabel,
  deleteOperation,
  dialogActions,
  darkGreenBtnColor,
  dialogWidth,
  btnDisabled = false,
  isInsideBaseline,
  isApproveModal,
  customContentSx,
  customSx,
  buttonSX,
  focusButtonPadding,
  buttonGap,
  dialogTitleSx,
  primaryButtonsMarginTop,
  isCloseEnabled = true,
  skipTitleCss = false,
  customDialogClassName = '',
  approveCtnLabel,
  dialogActionsApproval,
  showAcceptCtnLabel = true,
  subtitleSx = {},
}) => {
  const classname = `${checkTernaryCondition(
    isInsideBaseline,
    'dialog-box dialog-box-baseline',
    'dialog-box',
  )} ${customDialogClassName}`;

  return (
    <Dialog
      className={classname}
      sx={customSx}
      fullWidth={dialogWidth}
      open={isOpen}
      onClose={onCancel}>
      <DialogTitle
        className={checkTernaryCondition(!skipTitleCss, 'dialog-title', '')}
        sx={dialogTitleSx}>
        <Box
          display="flex"
          justifyContent={dialogTitleSx?.justifyContent || 'flex-end'}
          alignItems="center"
          sx={{
            color: deleteOperation ? '#DA291C' : DARK_CHARCOAL,
          }}>
          <div
            style={checkTernaryCondition(
              modalTitleSx && title.length > 50,
              modalTitleSx,
              checkTernaryCondition(isError, modalTitleSx, {
                flexGrow: 1,
              }),
            )}>
            {modalTitleSx ? (
              <LightTooltip title={title.slice(7)} sx={DialogTooltipSx}>
                {title}
              </LightTooltip>
            ) : (
              title
            )}
          </div>
          {isCloseEnabled ? (
            <CloseIcon
              onClick={onCancel}
              sx={{
                ...pointerCursor,
                alignSelf: 'flex-start',
                color: COOL_GRAY,
              }}
            />
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent className="dialog-content" sx={customContentSx}>
        <DialogContentText
          sx={{
            color: checkTernaryCondition(
              isInsideBaseline || isApproveModal,
              DARK_CHARCOAL,
              '',
            ),
            fontSize: checkTernaryCondition(isInsideBaseline, '0.875rem', ''),
            ...subtitleSx,
          }}>
          {subtitle}
        </DialogContentText>
        {children}
      </DialogContent>
      <div className="transactions-wrapper">
        {transactionList?.map((item) => (
          <span className="impacted-transactions" key={item}>
            {item}
          </span>
        ))}
      </div>
      {dialogActions && (
        <DialogActions
          sx={{
            padding: 0,
            gap: buttonGap,
            marginTop: primaryButtonsMarginTop,
          }}>
          <Button
            data-testid="cancel-button"
            onClick={onCancel}
            color={deleteOperation ? 'error' : 'primary'}
            sx={[
              {
                textTransform: 'none',
                fontWeight: '400',
                color: checkTernaryCondition(
                  darkGreenBtnColor,
                  DARK_GREEN,
                  'primary',
                ),
              },
              buttonSX,
              !deleteOperation && tertiaryButtonStyle,
            ]}>
            {declineCtnLabel}
          </Button>
          {showAcceptCtnLabel && (
            <Button
              data-testid="delete-button"
              onClick={onConfirm}
              disableElevation={true}
              color={deleteOperation ? 'error' : 'success'}
              variant="contained"
              sx={[
                {
                  textTransform: 'none',
                  fontWeight: '400',
                  backgroundColor: checkTernaryCondition(
                    darkGreenBtnColor,
                    DARK_GREEN,
                    'primary',
                  ),
                  '&:hover': {
                    backgroundColor: checkTernaryCondition(
                      darkGreenBtnColor,
                      DARK_GREEN,
                      'primary',
                    ),
                  },
                },
                buttonSX,
                !deleteOperation && primaryButtonStyle(focusButtonPadding),
              ]}
              disabled={btnDisabled}>
              {acceptCtnLabel}
            </Button>
          )}
        </DialogActions>
      )}
      {dialogActionsApproval && (
        <DialogActions
          sx={approvalModalDialogStyle(buttonGap, primaryButtonsMarginTop)}>
          <Button
            data-testid="delete-button"
            onClick={onCancel}
            disableElevation
            color={checkTernaryCondition(deleteOperation, 'error', 'success')}
            variant="contained"
            sx={[approvalModalButtonStyle(darkGreenBtnColor), buttonSX]}
            disabled={btnDisabled}>
            {approveCtnLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

DialogBox.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  transactionList: PropTypes.array,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  acceptCtnLabel: PropTypes.string,
  declineCtnLabel: PropTypes.string,
  deleteOperation: PropTypes.func,
  dialogActions: PropTypes.node,
  darkGreenBtnColor: PropTypes.string,
  dialogWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  btnDisabled: PropTypes.bool,
  isInsideBaseline: PropTypes.bool,
  customContentSx: PropTypes.object,
  customSx: PropTypes.object,
  buttonSX: PropTypes.object,
  dialogTitleSx: PropTypes.object,
  focusButtonPadding: PropTypes.string,
  buttonGap: PropTypes.string,
  primaryButtonsMarginTop: PropTypes.string,
  modalTitleSx: PropTypes.object,
  isError: PropTypes.bool,
  isApproveModal: PropTypes.bool,
  skipTitleCss: PropTypes.bool,
  customDialogClassName: PropTypes.string,
  isCloseEnabled: PropTypes.bool,
  approveCtnLabel: PropTypes.string,
  dialogActionsApproval: PropTypes.node,
  showAcceptCtnLabel: PropTypes.bool,
  subtitleSx: PropTypes.object,
};

export default DialogBox;
